export const getRol = (state) => {
    let userType = "";
    if (state.app?.user) {
      userType = "user";
    } else if (state.app?.driver) {
      userType = "driver";
    } else if (state.app?.passenger) {
      userType = "passenger";
    } else if (state.app?.client) {
      userType = "client";
    } else if (state.app?.supplier) {
      userType = "supplier";
    }
  
    return userType;
  };
  
  
  export const createHeaders = (state, userType) => ({
      Authorization: state.app[userType].token,
      usertype: userType + "_id",
      usertype_id: state.app[userType].id,
      usertype_company_id: state.app[userType].id,
  })