import React, { useState, useEffect } from 'react';
import { Accordion, Popup, Icon, Grid, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import './Sidebar.scss';
import styles from './../../styles/global.module.scss';
import { connect } from 'react-redux';
import driverActions from '../../store/actions/drivers';

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

const DriverSidebarMenu = (props) => {
	const { expand, setExpand, location, mobileOnly, setMobileOnly } = props;

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);
	const [isActive, setIsActive] = useState(false);
	const [availableRoutes, setAvailableRoutes] = useState([]);
	const [allowedRoutes, setAllowedRoutes] = useState([]);

	useEffect(() => {
		componentDidMount();
	}, []);

	useEffect(() => {
		function handleResize() {
			if (windowDimensions !== getWindowDimensions()) {
				setWindowDimensions(getWindowDimensions());
			}
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [windowDimensions]);

	useEffect(() => {
		if (windowDimensions.width <= 425) {
			setMobileOnly(true);
			if (expand === 1 || expand == null) setExpand(1);
		} else {
			setMobileOnly(false);
		}
	}, [windowDimensions]);

	useEffect(() => {
		if (location) {
			!mobileOnly ? setExpand(0) : setExpand(1);
		}
	}, [location]);

	/**
	 * Carga inicial de módulos y permisos
	 */
	const componentDidMount = async () => {
		const [availableRoutes, allowedRoutes] = await Promise.all([
			props.getDriverModules(),
			props.getWebDriverPermissions(props.driver.id),
		]);

		setAvailableRoutes(availableRoutes);
		setAllowedRoutes(allowedRoutes);
	};

	return (
		<div
			className="sidebar"
			style={expand == null || expand == 1 ? { width: '250px' } : null}
		>
			<Accordion
				key={`accordion-menu-sidebar`}
				fluid
				style={{ paddingLeft: '12px', paddingBottom: '5px' }}
			>
				{/* Filtrar módulos con al menos una ruta permitida */}
				{availableRoutes
					.filter((module) => {
						const moduleName = Object.keys(module)[0];
						const submodules = module[moduleName].routes;
						return submodules.some((route) => allowedRoutes.includes(route.url));
					})
					.map((module) => {
						const moduleName = Object.keys(module)[0];
						const submodules = module[moduleName].routes;

						return (
							<Accordion.Title
								key={`title-${moduleName}`}
								active={isActive}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setIsActive(!isActive);
								}}
							>
								<Popup
									trigger={
										<Menu.Item
											className={`${styles.shrinkOnHover} SubMenu__Item`}
											style={{ width: '100%', display: 'inline-block' }}
										>
											<Icon
												name={module[moduleName].icon}
												size="large"
												className="SideBar__Outline"
											/>
											{(expand == null || expand == 1) && (
												<h5 className="menu-row-title"> {moduleName} </h5>
											)}
										</Menu.Item>
									}
									positionFixed
									position="right center"
									pinned
									hoverable
									closeOnTriggerClick={true}
									offset={[0, 0]}
									className="SubMenu__Content"
									content={
										<Grid divided="vertically" style={{ width: '15rem' }}>
											<Grid.Row style={{ paddingBottom: 0 }}>
												<Grid.Column>
													<h4>{moduleName}</h4>
												</Grid.Column>
											</Grid.Row>
											<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
												<Grid.Column>
													<Accordion.Content
														key={`content-${moduleName}`}
														style={{
															marginTop: '0px',
															paddingTop: '0px',
														}}
														active={isActive}
													>
														<Menu
															vertical
															fluid
															text
															style={{ paddingLeft: '30px' }}
														>
															{/* Mostrar solo rutas permitidas */}
															{submodules
																.filter((route) =>
																	allowedRoutes.includes(route.url)
																)
																.map((route) => (
																	<Link
																		key={`link-${route.url}`}
																		to={route.url}
																		className="sub_menu_row"
																	>
																		<Menu.Item
																			className={styles.growOnHover}
																			style={{ margin: '0' }}
																		>
																			{route.name}
																		</Menu.Item>
																	</Link>
																))}
														</Menu>
													</Accordion.Content>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									}
								/>
							</Accordion.Title>
						);
					})}
			</Accordion>
		</div>
	);
};

const mapStateToProps = (state) => ({
	driver: state.app.driver,
});

const mapDispatchToProps = (dispatch) => ({
	getDriverModules: () => dispatch(driverActions.getDriverModules()),
	getWebDriverPermissions: (driverId) =>
		dispatch(driverActions.getWebDriverPermissions(driverId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverSidebarMenu);
