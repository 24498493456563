import { useState, Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Route, Redirect } from 'react-router-dom';

import { Header, Segment, Grid, Modal, Divider, List, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import MenuHeader from 'components/Header/SupplierHeader';
import SupplierSidebarMenu from 'components/Sidebar/SupplierSidebarMenu';
import supplierActions from '../../store/actions/suppliers';

const PrivateSupplierRoute = (props) => {
	const { isAuthenticated = true, component: Component, ...rest } = props;

	const [expand, setExpand] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [mobileOnly, setMobileOnly] = useState(false);
	const [previousExpand, setPreviousExpand] = useState(null);

	const [availableRoutes, setAvailableRoutes] = useState([]);
	const [allowedRoutes, setAllowedRoutes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		componentDidMount();
	}, []);

	/**
	 * Inicializa los módulos y permisos disponibles
	 */
	const componentDidMount = async () => {
		const [availableRoutes, allowedRoutes] = await Promise.all([
			props.getSupplierModules(),
			props.getWebSupplierPermissions(props.supplier.id),
		]);

		setAvailableRoutes(availableRoutes);
		setAllowedRoutes(allowedRoutes);
		setIsLoading(false);
	};

	/**
	 * Componente de fallback para rutas no permitidas
	 */
	const ModuleNotFound = () => (
		<div style={{ padding: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Header as="h1" icon="warning circle" content="Módulo no encontrado" />
			<p>No tienes acceso a este módulo o no existe.</p>
		</div>
	);

	return (
		<>
			<Route
				{...rest}
				render={(props) => {
					// Verificar si la ruta está permitida
					const isAllowed = allowedRoutes.some((allowedRoute) =>
						props.location.pathname.startsWith(allowedRoute)
					);

					return isAuthenticated ? (
						<Fragment>
							<MenuHeader
								rest={rest}
								logout={rest.logout}
								history={props.history}
								expand={expand}
								setExpand={setExpand}
								previousExpand={previousExpand}
								setPreviousExpand={setPreviousExpand}
								openModal={openModal}
								setOpenModal={setOpenModal}
								mobileOnly={mobileOnly}
								setMobileOnly={setMobileOnly}
							/>
							<div
								className="main-content"
								style={
									expand == null || expand == 1
										? { marginLeft: '250px' }
										: null
								}
							>
								{ReactDOM.createPortal(
									<SupplierSidebarMenu
										history={props.history}
										location={props.location}
										client={props.client}
										rest={rest}
										expand={expand}
										setExpand={setExpand}
										previousExpand={previousExpand}
										setPreviousExpand={setPreviousExpand}
										openModal={openModal}
										setOpenModal={setOpenModal}
										mobileOnly={mobileOnly}
										setMobileOnly={setMobileOnly}
									/>,
									document.getElementById('root')
								)}
								{/* Renderizar el componente o el fallback */}
								{isLoading ? (
									<Dimmer active>
										<Loader>
											Cargando...
										</Loader>
									</Dimmer>
								) : (
									isAllowed ? <Component {...props} /> : <ModuleNotFound />
								)}
							</div>
						</Fragment>
					) : (
						<Redirect to="/login_supplier" />
					);
				}}
			/>
			<Modal
				closeIcon
				basic
				centered={true}
				onClose={() => setOpenModal(false)}
				onOpen={() => setOpenModal(true)}
				open={openModal}
				size="large"
			>
				<Modal.Content>
					<Segment inverted style={{ height: '100%' }}>
						<Grid stackable divided inverted>
							<Grid.Row columns={2}>
								<Grid.Column
									width={6}
									verticalAlign="middle"
									textAlign="center"
								>
									<Header inverted content="© Geologistic GPS 2022" />
								</Grid.Column>
								<Grid.Column width={8}>
									<Header inverted content="Contacto" />
									<Divider />
									<Header
										size="small"
										inverted
										content="¿Necesitas registrar un nuevo ticket o solicitud?"
									/>
									<List divided inverted>
										<List.Item
											header="Ingresa al Portal: "
											content="https://geologistic.com.mx/Soporte/"
											style={{ cursor: 'pointer' }}
											onClick={() => {
												window.open(
													'https://geologistic.com.mx/Soporte/',
													'_blank'
												);
											}}
										/>
										<List.Item
											header="Soporte"
											content="(686) 214 5447, (686) 385 6938,  (686) 510 9915"
										/>
										<List.Item header="Oficina" content="(686) 372 9877" />
										<List.Item
											header="Emergencias"
											content="(686) 420 3333, (686) 214 5447, (686) 385 6938, (686) 510 9915,  (686) 123 6454"
										/>
										<List.Item
											header="Administración"
											content="(686) 122 0316"
										/>
										<List.Item
											header="Ventas"
											content="(686) 510 9915, (686) 196 4736, (686) 420 8586,  (686) 191 1571"
										/>
									</List>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</Modal.Content>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.app.supplier,
	supplier: state.app.supplier,
});

const mapDispatchToProps = (dispatch) => ({
	getSupplierModules: () => dispatch(supplierActions.getSupplierModules()),
	getWebSupplierPermissions: (supplierId) =>
		dispatch(supplierActions.getWebSupplierPermissions(supplierId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateSupplierRoute);
