import React, { useState, useEffect } from 'react'

import { Accordion, Popup, Icon, Grid, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import './Sidebar.scss'
import styles from './../../styles/global.module.scss'
import { connect } from 'react-redux'

import clientActions from '../../store/actions/clients'

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window
	return {
		width,
		height,
	}
}

const ClientSidebarMenu = (props) => {
	const { expand, setExpand, client, location, mobileOnly, setMobileOnly } =
		props

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	)
	const [isActive, setIsActive] = useState(false)
	const [isSubActive, setIsSubActive] = useState(false)
	const [availableRoutes, setAvailableRoutes] = useState([])
	const [allowedRoutes, setAllowedRoutes] = useState([])

	useEffect(() => {
		componentDidMount()
	}, [])

	const componentDidMount = async () => {
		const [availableRoutes, allowedRoutes] = await Promise.all([
			props.getClientModules(),
			props.getWebClientPermissions(props.client.id),
		])

		setAvailableRoutes(availableRoutes)
		setAllowedRoutes(allowedRoutes)
	}

	useEffect(() => {
		function handleResize() {
			if (windowDimensions !== getWindowDimensions())
				setWindowDimensions(getWindowDimensions())
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	useEffect(() => {
		if (windowDimensions.width <= 425) {
			setMobileOnly(true)
			if (expand === 1 || expand == null) setExpand(1)
		} else {
			setMobileOnly(false)
		}
	}, [windowDimensions])

	useEffect(() => {
		if (location) {
			!mobileOnly ? setExpand(0) : setExpand(1)
		}
	}, [location])

	// return (
	// 	<>
	// 		<div
	// 			className='sidebar'
	// 			style={expand == null || expand == 1 ? { width: '250px' } : null}
	// 		>
	// 			<Accordion
	// 				key={`accordion-menu-sidebar`}
	// 				fluid
	// 				style={{ paddingLeft: '12px', paddingBottom: '5px' }}
	// 			>
	// 				<Accordion.Title
	// 					key={`title-sidebar`}
	// 					active={isActive}
	// 					onClick={(e) => {
	// 						e.preventDefault()
	// 						e.stopPropagation()
	// 						setIsActive(!isActive)
	// 					}}
	// 				>
	// 					<Popup
	// 						trigger={
	// 							<Menu.Item
	// 								className={`${styles.shrinkOnHover} SubMenu__Item`}
	// 								style={{ width: '100%', display: 'inline-block' }}
	// 							>
	// 								<Icon
	// 									name='child'
	// 									size='large'
	// 									className='SideBar__Outline'
	// 								/>
	// 								{(expand == null || expand == 1) && (
	// 									<h5 className='menu-row-title'> Entidades </h5>
	// 								)}
	// 							</Menu.Item>
	// 						}
	// 						positionFixed
	// 						position='right center'
	// 						pinned
	// 						hoverable
	// 						closeOnTriggerClick={true}
	// 						offset={[0, 0]}
	// 						className='SubMenu__Content'
	// 						content={
	// 							<Grid divided='vertically' style={{ width: '15rem' }}>
	// 								<Grid.Row style={{ paddingBottom: 0 }}>
	// 									<Grid.Column>
	// 										<h4>Entidades</h4>
	// 									</Grid.Column>
	// 								</Grid.Row>
	// 								<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
	// 									<Grid.Column>
	// 										<Accordion.Content
	// 											key={`content-entidades`}
	// 											style={{ marginTop: '0px', paddingTop: '0px' }}
	// 											active={isActive}
	// 										>
	// 											<Menu
	// 												vertical
	// 												fluid
	// 												text
	// 												style={{ paddingLeft: '30px' }}
	// 											>
	// 												<Link
	// 													key={`link-dashboard`}
	// 													to={`/client/client_dashboard`}
	// 													className='sub_menu_row'
	// 												>
	// 													<Menu.Item
	// 														className={styles.growOnHover}
	// 														style={{ margin: '0' }}
	// 													>
	// 														Dashboard
	// 													</Menu.Item>
	// 												</Link>
	// 												<Link
	// 													key={`link-passengers`}
	// 													to={`/client/passengers`}
	// 													className='sub_menu_row'
	// 												>
	// 													<Menu.Item
	// 														className={styles.growOnHover}
	// 														style={{ margin: '0' }}
	// 													>
	// 														Pasajeros
	// 													</Menu.Item>
	// 												</Link>
	// 												<Link
	// 													key={`link-passengers-report`}
	// 													to={`/client/passengers_report`}
	// 													className='sub_menu_row'
	// 												>
	// 													<Menu.Item
	// 														className={styles.growOnHover}
	// 														style={{ margin: '0' }}
	// 													>
	// 														Reporte de Pasajeros
	// 													</Menu.Item>
	// 												</Link>
	// 												<Link
	// 													key={`link-groups-report`}
	// 													to={`/client/groups_report`}
	// 													className='sub_menu_row'
	// 												>
	// 													<Menu.Item
	// 														className={styles.growOnHover}
	// 														style={{ margin: '0' }}
	// 													>
	// 														Reporte de Grupos
	// 													</Menu.Item>
	// 												</Link>
	// 												<Link
	// 													key={`link-groups-report`}
	// 													to={`/client/client_group_passengers`}
	// 													className='sub_menu_row'
	// 												>
	// 													<Menu.Item
	// 														className={styles.growOnHover}
	// 														style={{ margin: '0' }}
	// 													>
	// 														Grupo de Pasajeros
	// 													</Menu.Item>
	// 												</Link>
	// 											</Menu>
	// 										</Accordion.Content>
	// 									</Grid.Column>
	// 								</Grid.Row>
	// 							</Grid>
	// 						}
	// 					/>
	// 					<Popup
	// 						trigger={
	// 							<Menu.Item
	// 								className={`${styles.shrinkOnHover} SubMenu__Item`}
	// 								style={{
	// 									width: '100%',
	// 									display: 'inline-block',
	// 									marginTop: '15px',
	// 								}}
	// 							>
	// 								<Icon name='bus' size='large' className='SideBar__Outline' />
	// 								{(expand == null || expand == 1) && (
	// 									<h5 className='menu-row-title'> Transporte de Personal </h5>
	// 								)}
	// 							</Menu.Item>
	// 						}
	// 						positionFixed
	// 						position='right center'
	// 						pinned
	// 						hoverable
	// 						closeOnTriggerClick={true}
	// 						offset={[0, 0]}
	// 						className='SubMenu__Content'
	// 						content={
	// 							<Grid divided='vertically' style={{ width: '15rem' }}>
	// 								<Grid.Row style={{ paddingBottom: 0 }}>
	// 									<Grid.Column>
	// 										<h4> Transporte de Personal</h4>
	// 									</Grid.Column>
	// 								</Grid.Row>
	// 								<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
	// 									<Grid.Column>
	// 										<Accordion.Content
	// 											key={`content-transporte de personal`}
	// 											style={{ marginTop: '0px', paddingTop: '0px' }}
	// 											active={isActive}
	// 										>
	// 											<Menu
	// 												vertical
	// 												fluid
	// 												text
	// 												style={{ paddingLeft: '30px' }}
	// 											>
	// 												<Link
	// 													key={`link-mobile_routes_report`}
	// 													to={`/client/mobile_routes_report`}
	// 													className='sub_menu_row'
	// 												>
	// 													<Menu.Item
	// 														className={styles.growOnHover}
	// 														style={{ margin: '0' }}
	// 													>
	// 														Reporte de Rutas Móviles
	// 													</Menu.Item>
	// 												</Link>
	// 											</Menu>
	// 										</Accordion.Content>
	// 									</Grid.Column>
	// 								</Grid.Row>
	// 							</Grid>
	// 						}
	// 					/>

	// 					<Popup
	// 						trigger={
	// 							<Menu.Item
	// 								className={`${styles.shrinkOnHover} SubMenu__Item`}
	// 								style={{
	// 									width: '100%',
	// 									display: 'inline-block',
	// 									marginTop: '15px',
	// 								}}
	// 							>
	// 								<Icon
	// 									name='wordpress forms'
	// 									size='large'
	// 									className='SideBar__Outline'
	// 								/>
	// 								{(expand == null || expand == 1) && (
	// 									<h5 className='menu-row-title'> Formularios </h5>
	// 								)}
	// 							</Menu.Item>
	// 						}
	// 						positionFixed
	// 						position='right center'
	// 						pinned
	// 						hoverable
	// 						closeOnTriggerClick={true}
	// 						offset={[0, 0]}
	// 						className='SubMenu__Content'
	// 						content={
	// 							<Grid divided='vertically' style={{ width: '15rem' }}>
	// 								<Grid.Row style={{ paddingBottom: 0 }}>
	// 									<Grid.Column>
	// 										<h4> Formularios</h4>
	// 									</Grid.Column>
	// 								</Grid.Row>
	// 								<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
	// 									<Grid.Column>
	// 										<Accordion.Content
	// 											key={`content-transporte de personal`}
	// 											style={{ marginTop: '0px', paddingTop: '0px' }}
	// 											active={isActive}
	// 										>
	// 											<Menu
	// 												vertical
	// 												fluid
	// 												text
	// 												style={{ paddingLeft: '30px' }}
	// 											>
	// 												<Link
	// 													key={`link-mobile_routes_report`}
	// 													to={'/client/form_instance'}
	// 													className='sub_menu_row'
	// 												>
	// 													<Menu.Item
	// 														className={styles.growOnHover}
	// 														style={{ margin: '0' }}
	// 													>
	// 														Responder Formulario
	// 													</Menu.Item>
	// 												</Link>

	// 												<Link
	// 													key={`link-mobile_routes_report`}
	// 													to={`/client/form_instance_report_detail`}
	// 													className='sub_menu_row'
	// 												>
	// 													<Menu.Item
	// 														className={styles.growOnHover}
	// 														style={{ margin: '0' }}
	// 													>
	// 														Reporte detallado de procesos
	// 													</Menu.Item>
	// 												</Link>
	// 											</Menu>
	// 										</Accordion.Content>
	// 									</Grid.Column>
	// 								</Grid.Row>
	// 							</Grid>
	// 						}
	// 					/>

	// 					<Popup
	// 						trigger={
	// 							<Menu.Item
	// 								className={`${styles.shrinkOnHover} SubMenu__Item`}
	// 								style={{
	// 									width: '100%',
	// 									display: 'inline-block',
	// 									marginTop: '15px',
	// 								}}
	// 							>
	// 								<Icon
	// 									name='folder open'
	// 									size='large'
	// 									className='SideBar__Outline'
	// 								/>
	// 								{(expand == null || expand == 1) && (
	// 									<h5 className='menu-row-title'> Documentos </h5>
	// 								)}
	// 							</Menu.Item>
	// 						}
	// 						positionFixed
	// 						position='right center'
	// 						pinned
	// 						hoverable
	// 						closeOnTriggerClick={true}
	// 						offset={[0, 0]}
	// 						className='SubMenu__Content'
	// 						content={
	// 							<Grid divided='vertically' style={{ width: '15rem' }}>
	// 								<Grid.Row style={{ paddingBottom: 0 }}>
	// 									<Grid.Column>
	// 										<h4>Documentos</h4>
	// 									</Grid.Column>
	// 								</Grid.Row>
	// 								<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
	// 									<Grid.Column>
	// 										<Accordion.Content
	// 											key={`content-documentos`}
	// 											style={{ marginTop: '0px', paddingTop: '0px' }}
	// 											active={isActive}
	// 										>
	// 											<Menu vertical fluid text style={{ paddingLeft: '30px' }}>
	// 												<Link
	// 													key={`link-dashboard`}
	// 													to={`/client/client_documents`}
	// 													className='sub_menu_row'
	// 												>
	// 													<Menu.Item className={styles.growOnHover} style={{ margin: '0' }}>
	// 														Documentos
	// 													</Menu.Item>
	// 												</Link>
	// 											</Menu>
	// 										</Accordion.Content>
	// 									</Grid.Column>
	// 								</Grid.Row>
	// 							</Grid>
	// 						}
	// 					/>
	// 				</Accordion.Title>
	// 			</Accordion>
	// 		</div>
	// 	</>
	// )

	return (
		<div
			className='sidebar'
			style={expand == null || expand == 1 ? { width: '250px' } : null}
		>
			<Accordion
				key={`accordion-menu-sidebar`}
				fluid
				style={{ paddingLeft: '12px', paddingBottom: '5px' }}
			>
				{/* Filtrar módulos con rutas permitidas */}
				{availableRoutes
					.filter((module) => {
						const moduleName = Object.keys(module)[0]
						const submodules = module[moduleName].routes
						// Verificar si el módulo tiene al menos una ruta permitida
						return submodules.some((route) => allowedRoutes.includes(route.url))
					})
					.map((module) => {
						const moduleName = Object.keys(module)[0]
						const submodules = module[moduleName].routes

						return (
							<Accordion.Title
								key={`title-${moduleName}`}
								active={isActive}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									setIsActive(!isActive)
								}}
							>
								<Popup
									trigger={
										<Menu.Item
											className={`${styles.shrinkOnHover} SubMenu__Item`}
											style={{ width: '100%', display: 'inline-block' }}
										>
											<Icon
												name={module[moduleName].icon}
												size='large'
												className='SideBar__Outline'
											/>
											{(expand == null || expand == 1) && (
												<h5 className='menu-row-title'> {moduleName} </h5>
											)}
										</Menu.Item>
									}
									positionFixed
									position='right center'
									pinned
									hoverable
									closeOnTriggerClick={true}
									offset={[0, 0]}
									className='SubMenu__Content'
									content={
										<Grid divided='vertically' style={{ width: '15rem' }}>
											<Grid.Row style={{ paddingBottom: 0 }}>
												<Grid.Column>
													<h4>{moduleName}</h4>
												</Grid.Column>
											</Grid.Row>
											<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
												<Grid.Column>
													<Accordion.Content
														key={`content-${moduleName}`}
														style={{ marginTop: '0px', paddingTop: '0px' }}
														active={isActive}
													>
														<Menu
															vertical
															fluid
															text
															style={{ paddingLeft: '30px' }}
														>
															{/* Filtrar y mostrar solo los links permitidos */}
															{submodules
																.filter((route) =>
																	allowedRoutes.includes(route.url)
																)
																.map((route) => (
																	<Link
																		key={`link-${route.url}`}
																		to={route.url}
																		className='sub_menu_row'
																	>
																		<Menu.Item
																			className={styles.growOnHover}
																			style={{ margin: '0' }}
																		>
																			{route.name}
																		</Menu.Item>
																	</Link>
																))}
														</Menu>
													</Accordion.Content>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									}
								/>
							</Accordion.Title>
						)
					})}
			</Accordion>
		</div>
	)
}

const mapStateToProps = (state) => ({
	client: state.app.client,
})

const mapDispatchToProps = (dispatch) => ({
	getClientModules: () => dispatch(clientActions.getClientModules()),
	getWebClientPermissions: (clientId) =>
		dispatch(clientActions.getWebClientPermissions(clientId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientSidebarMenu)

/* 
	availableRoutes look like this: 
	[
	{
		"Entidades": {
			"icon": "child",
			"routes": [
				{
					"url": "/client/client_dashboard",
					"name": "Dashboard",
					"folder": "ClientDashboardPage.js"
				},
				{
					"url": "/client/passengers",
					"name": "Pasajeros",
					"folder": "ClientPassengersPage.js"
				},
				{
					"url": "/client/passengers_report",
					"name": "Reporte de Pasajeros",
					"folder": "ClientPassengersReportPage.js"
				},
				{
					"url": "/client/groups_report",
					"name": "Reporte de Grupos",
					"folder": "ClientGroupsReportPage.js"
				},
				{
					"url": "/client/client_group_passengers",
					"name": "Pasajeros por Grupo",
					"folder": "ClientGroupPassengersPage.js"
				}
			]
		}
	},
	{
		"Transporte de Personal": {
			"icon": "bus",
			"routes": [
				{
					"url": "/client/mobile_routes_report",
					"name": "Reporte de Rutas Móviles",
					"folder": "ClientMobileRoutesReportPage.js"
				}
			]
		}
	},
	{
		"Formularios": {
			"icon": "wordpress forms",
			"routes": [
				{
					"url": "/client/form_instance",
					"name": "Responder Formulario",
					"folder": "ClientFormInstancePage.js"
				},
				{
					"url": "/client/form_instance_report_detail",
					"name": "Reporte detallado de procesos",
					"folder": "ClientFormReportDetailPage.js"
				}
			]
		}
	},
	{
		"Documentos": {
			"icon": "folder open",
			"routes": [
				{
					"url": "/client/client_documents",
					"name": "Documentos",
					"folder": "ClientDocumentsPage.js"
				}
			]
		}
	}
]

	allowedRoutes look like this:
	['/client/client_dashboard', '/client/passengers', '/client/passengers_report', '/client/groups_report', '/client/client_group_passengers', '/client/mobile_routes_report', '/client/form_instance', '/client/form_instance_report_detail', '/client/client_documents']

*/
