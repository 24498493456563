import React, { Suspense, lazy, useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import PublicRoute from 'components/Routes/PublicRoute'
import PublicClientRoute from 'components/Routes/PublicClientRoute'
import PublicDriverRoute from 'components/Routes/PublicDriverRoute'
import PrivateRoute from 'components/Routes/PrivateRoute'
import PrivateClientRoute from 'components/Routes/PrivateClientRoute'
import PrivateDriverRoute from 'components/Routes/PrivateDriverRoute'
import PublicPassengerRoute from 'components/Routes/PublicPassengerRoute'
import PrivatePassengerRoute from 'components/Routes/PrivatePassengerRoute'
import PrivateAssociateRoute from 'components/Routes/PrivateAssociateRoute'
import {
	loginWithToken,
	clientLoginWithToken,
	driverLoginWithToken,
	passengerLoginWithToken,
	setLanguage,
	supplierLoginWithToken,
	associateLoginWithToken,
} from 'store/actions/app'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import SupplierLogin from './login/SupplierLogin'
import PrivateSupplierRoute from 'components/Routes/PrivateSupplierRoute'
import PublicSupplierRoute from 'components/Routes/PublicSupplierRoute'
import WizardCatalog from './wizard/WizardCatalog'
import ModuleNotFound from './error/moduleNotFound'
// import ClientGroupPassengersPage from './transporte de personal/ClientPassengerSite/ClientGroupPassengersPage'

const HomePage = lazy(() => import('./home/HomePage'))
const LoginRoute = lazy(() => import('./login/index'))
const RolePage = lazy(() => import('./settings/RolePage'))
const RoleIndividualPage = lazy(() => import('./settings/RoleIndividualPage'))

const SupplierDocumentsPage = lazy(() =>
	import('./transporte de personal/SupplierSite/SupplierDocumentsPage')
)

const ClientDocumentsPage = lazy(() =>
	import('./transporte de personal/ClientPassengerSite/ClientDocumentPage')
)

const ClientPassengersPage = lazy(() =>
	import('./transporte de personal/ClientPassengerSite/ClientPassengersPage')
)
const ClientPassengersIndividualPage = lazy(() =>
	import(
		'./transporte de personal/ClientPassengerSite/ClientPassengersIndividualPage'
	)
)
const ClientPassengersReportPage = lazy(() =>
	import(
		'./transporte de personal/ClientPassengerSite/ClientPassengersReportPage'
	)
)
const ClientDashboardPage = lazy(() =>
	import('./transporte de personal/ClientPassengerSite/ClientDashboardPage')
)
const ClientGroupsReportPage = lazy(() =>
	import('./transporte de personal/ClientPassengerSite/ClientGroupsReportPage')
)
const PassengersIndividualPage = lazy(() =>
	import('./transporte de personal/PassengerSite/PassengersIndividualPage')
)

const ClientGroupPassengersPage = lazy(() =>
	import(
		'./transporte de personal/ClientPassengerSite/ClientGroupPassengersPage'
	)
)

const ClientGroupPassengersIndividualPage = lazy(() =>
	import(
		'./transporte de personal/ClientPassengerSite/ClientGroupPassengersIndividualPage'
	)
)

const DriversPage = lazy(() =>
	import('./transporte de personal/DriverSite/DriversIndividualPage')
)

const DeliveryRoutesReport = lazy(() =>
	import('./transporte de personal/DriverSite/DriverDeliveryRoutesReport')
)

const ClientVisits = lazy(() =>
	import('./transporte de mercancia/ClientVisitsIndividualPage')
)

const DriverDashboardPage = lazy(() =>
	import('./transporte de mercancia/DriverDashboardPage')
)

const ClientMobileRoutesReportPage = lazy(() =>
	import(
		'./transporte de personal/ClientPassengerSite/ClientMobileRoutesReportPage'
	)
)

const ClientFormInstancePage = lazy(() =>
	import('./transporte de personal/ClientPassengerSite/ClientFormInstancePage')
)

const DriverFormInstancesPage = lazy(() =>
	import('./transporte de personal/DriverSite/DriverFormInstancesPage')
)

const DriverFormInstancePage = lazy(() =>
	import('./transporte de personal/DriverSite/DriverFormInstancePage')
)

const PassengerFormInstancePage = lazy(() =>
	import('./transporte de personal/PassengerSite/PassengerFormInstancePage')
)

const PassengerFormReportDetailPage = lazy(() =>
	import('./transporte de personal/PassengerSite/PassengerFormDetailPage')
)

const SupplierFormInstancePage = lazy(() =>
	import('./transporte de personal/SupplierSite/SupplierFormInstancePage.js')
)

const SupplierFormReportDetailPage = lazy(() =>
	import('./transporte de personal/SupplierSite/SupplierFormReportDetailPage')
)

const DriverInspectionPage = lazy(() => import('./driver/DriverInspectionPage'))
const SupplierHomePage = lazy(() => import('./home/SupplierHomePage'))

const ClientFormReportDetailPage = lazy(() =>
	import('./transporte de personal/ClientPassengerSite/ClientFormDetailPage')
)

const DriverFormReportDetailPage = lazy(() =>
	import('./transporte de personal/DriverSite/DriverFormDetailPage')
)

const ConsoleSuperAdmin = lazy(() => import('./login/ConsoleSuperAdmin'))
const ProcessCatalog = lazy(() =>
	import('../routes/process_catalog/ProcessCatalog')
)
const Procedure = lazy(() => import('../routes/wizard/WizardProcedure'))
const ProcedureIndiv = lazy(() =>
	import('../routes/wizard/WizardProcedureIndividual')
)
const FormatImpression = lazy(() =>
	import('../routes/wizard/WizardImpressionFormats')
)
const FormatImpressionIndiv = lazy(() =>
	import('../routes/wizard/WizardImpressionFormatsIndividual')
)
const ProcessIndiv = lazy(() =>
	import('../routes/process_catalog/ProcessCatalogIndividual')
)
const Wizard = lazy(() => import('../routes/wizard/WizardCatalog'))
const WizardIndiv = lazy(() =>
	import('../routes/wizard/WizardCatalogIndividual')
)
const Communication = lazy(() =>
	import('../routes/wizard/WizardCommunications')
)
const CommunicationIndiv = lazy(() => import('../routes/wizard/WizardCatalog'))
const AssociateLogin = lazy(() => import('./login/AssociateLogin'))
const AssociatesPage = lazy(() => import('./associates/AssociatesPage'))
const AssociatesIndividualPage = lazy(() =>
	import('./associates/AssociatesIndividualPage')
)
const ClientLogin = lazy(() => import('./login/ClientLogin'))
const DriverLogin = lazy(() => import('./login/DriverLogin'))

const PassengerLogin = lazy(() => import('./login/PassengerLogin'))

const PublicForm = lazy(() => import('../routes/form/PublicForm'))

const Routers = (props) => {
	const [isTryingToLogin, setIsTryingToLogin] = useState(true)

	const tryToLogin = async () => {
		const token = localStorage.getItem('@token')
		const client_token = localStorage.getItem('@client_token')
		const passenger_token = localStorage.getItem('@passenger_token')
		const employee_code = localStorage.getItem('@employee_code')
		const driver_token = localStorage.getItem('@driver_token')
		const supplier_token = localStorage.getItem('@supplier_token')
		const super_admin_token = localStorage.getItem('@super_admin_token')
		if (token) {
			if (employee_code) {
				await props.loginWithToken({ token, employee_code })
			} else {
				await props.loginWithToken({ token })
			}
		}
		if (client_token) {
			await props.clientLoginWithToken({ client_token })
		}
		if (passenger_token) {
			await props.passengerLoginWithToken({ passenger_token })
		}
		if (driver_token) {
			await props.driverLoginWithToken({ driver_token })
		}
		if (supplier_token) {
			await props.supplierLoginWithToken({ supplier_token })
		}
		if (super_admin_token) {
			await props.associateLoginWithToken(super_admin_token)
		}

		setIsTryingToLogin(false)
	}
	const getLanguage = () => {
		if (!props.lang) {
			const lang = localStorage.getItem('@language')
			if (lang) props.setLanguage(lang, false)
		}
	}
	useEffect(() => {
		tryToLogin()
		getLanguage()
	}, [])

	if (isTryingToLogin) {
		return (
			<Dimmer active inverted className='padding-0'>
				<Loader inverted>Cargando</Loader>
			</Dimmer>
		)
	}

	const modulesToRender = props.user?.modules.filter((mod) => {
		const { permisitions } = mod

		return permisitions?.ACCESS
	})

	return (
		<React.Fragment>
			<BrowserRouter>
				<Suspense
					fallback={
						<Segment className='width-100 height-100 padding-0 border-none margin-0 border-radius-0'>
							<Dimmer active inverted className='padding-0'>
								<Loader inverted>Cargando página</Loader>
							</Dimmer>
						</Segment>
					}
				>
					<Switch>
						<PublicRoute
							path='/login_superadmin'
							component={AssociateLogin}
							exact={true}
						/>
						<PublicClientRoute
							path='/login_client'
							component={ClientLogin}
							exact={true}
						/>
						<PublicPassengerRoute
							path='/login_passenger'
							component={PassengerLogin}
							exact={true}
						/>
						<PublicDriverRoute
							path='/login_driver'
							component={DriverLogin}
							exact={true}
						/>
						<PublicSupplierRoute
							path='/login_supplier'
							component={SupplierLogin}
							exact={true}
						/>
						<PublicRoute
							path='/login_superadmin'
							component={ConsoleSuperAdmin}
							exact={true}
						/>
						<PublicClientRoute
							path='/login_client'
							component={ClientLogin}
							exact={true}
						/>
						<PublicPassengerRoute
							path='/login_passenger'
							component={PassengerLogin}
							exact={true}
						/>
						<PublicDriverRoute
							path='/login_driver'
							component={DriverLogin}
							exact={true}
						/>
						<PublicDriverRoute
							path='/public_forms'
							component={PublicForm}
							exact={true}
						/>
						<PublicDriverRoute
							path='/public_forms/:id'
							component={PublicForm}
							exact={true}
						/>
						<PublicRoute path='/login' component={LoginRoute} exact={true} />
						<PrivateRoute path='/' component={HomePage} exact={true} />
						<PrivateRoute
							path='/settings/role'
							component={RolePage}
							exact={true}
						/>
						<PrivateRoute
							path='/settings/role/:id'
							component={RoleIndividualPage}
							exact={true}
						/>

						{props.isSupplierAuthenticated && (
							<>
								<PrivateSupplierRoute
									path='/supplier/supplier_dashboard'
									component={SupplierHomePage}
									exact={true}
								/>
								<PrivateSupplierRoute
									path='/supplier/form_instance'
									component={SupplierFormInstancePage}
									exact={true}
								/>
								<PrivateSupplierRoute
									path='/supplier/form_instance_report_detail'
									component={SupplierFormReportDetailPage}
									exact={true}
								/>
								<PrivateSupplierRoute
									path='/supplier/supplier_documents'
									component={SupplierDocumentsPage}
									exact={true}
								/>
							</>
						)}

						{props.isClientAuthenticated && (
							<>
								<PrivateClientRoute
									path='/client/passengers'
									component={ClientPassengersPage}
									exact={true}
								/>
								<PrivateClientRoute
									path='/client/passengers/:id'
									component={ClientPassengersIndividualPage}
									exact={true}
								/>
								<PrivateClientRoute
									path='/client/passengers_report'
									component={ClientPassengersReportPage}
									exact={true}
								/>
								<PrivateClientRoute
									path='/client/client_dashboard'
									component={ClientDashboardPage}
									exact={true}
								/>
								<PrivateClientRoute
									path='/client/groups_report'
									component={ClientGroupsReportPage}
									exact={true}
								/>
								<PrivateClientRoute
									path='/client/client_group_passengers'
									component={ClientGroupPassengersPage}
									exact={true}
								/>
								<PrivateClientRoute
									path='/client/client_group_passengers/:id'
									component={ClientGroupPassengersIndividualPage}
									exact={true}
								/>
								<PrivateClientRoute
									path='/client/mobile_routes_report'
									component={ClientMobileRoutesReportPage}
									exact={true}
								/>
								<PrivateClientRoute
									path='/client/form_instance'
									component={ClientFormInstancePage}
									exact={true}
								/>
								<PrivateClientRoute
									path='/client/form_instance_report_detail'
									component={ClientFormReportDetailPage}
									exact={true}
								/>
								<PrivateClientRoute
									path='/client/client_documents'
									component={ClientDocumentsPage}
									exact={true}
								/>
							</>
						)}

						{props.isPassengerAuthenticated && (
							<>
								<PrivatePassengerRoute
									path='/passenger/passengers/'
									component={PassengersIndividualPage}
									exact={true}
								/>

								<PrivatePassengerRoute
									path='/passenger/form_instance/'
									component={PassengerFormInstancePage}
									exact={true}
								/>

								<PrivatePassengerRoute
									path='/passenger/form_instance_report_detail'
									component={PassengerFormReportDetailPage}
									exact={true}
								/>
							</>
						)}

						{props.isDriverAuthenticated && (
							<>
								<PrivateDriverRoute
									path='/driver/drivers'
									component={DriversPage}
									exact={true}
								/>
								<PrivateDriverRoute
									path='/driver/delivery_route_report'
									component={DeliveryRoutesReport}
									exact={true}
								/>
								<PrivateDriverRoute
									path='/driver/client_visit'
									component={ClientVisits}
									exact={true}
								/>
								<PrivateDriverRoute
									path='/driver/driver_dashboard'
									component={DriverDashboardPage}
									exact={true}
								/>
								<PrivateDriverRoute
									path='/driver/driver_inspection_page'
									component={DriverInspectionPage}
									exact={true}
								/>
								<PrivateDriverRoute
									path='/driver/form_instances'
									component={DriverFormInstancesPage}
									exact={true}
								/>
								<PrivateDriverRoute
									path='/driver/form_instances/:id'
									component={DriverFormInstancePage}
									exact={true}
								/>
								<PrivateDriverRoute
									path='/driver/create_form_instance'
									component={DriverFormInstancePage}
									exact={true}
								/>

								<PrivateDriverRoute
									path='/driver/form_instance_report_detail'
									component={DriverFormReportDetailPage}
									exact={true}
								/>
							</>
						)}

						{props.isAuthenticated && (
							<>
								{modulesToRender.map((m) => {
									const ComponentPage = lazy(() => {
										if (
											typeof m.folder === 'string' &&
											m.folder.trim() !== ''
										) {
											return import(`${m.folder}`).catch((e) => {
												console.warn(
													`No se pudo cargar el módulo en ${m.folder}. Cargando la página de error.`
												)
												return import('./error/moduleNotFound')
											})
										} else {
											console.warn(
												`Ruta de módulo inválida o vacía: ${m.folder}. Cargando la página de error.`
											)
											return import('./error/moduleNotFound')
										}
									})

									if (ComponentPage)
										return (
											<PrivateRoute
												path={`/${m.url}`}
												component={ComponentPage}
												title={m.name}
												exact={true}
												key={m.url}
											/>
										)
								})}
							</>
						)}

						{props.isAssociateAuthenticated && (
							<PrivateAssociateRoute
								path='/sys_company_administrator'
								component={ConsoleSuperAdmin}
								exact={true}
							/>
						)}

						{props.isSuperAdminAuthenticated && (
							<>
								<PrivateAssociateRoute
									path='/process_catalog'
									component={ProcessCatalog}
									exact={true}
								/>
								<PrivateAssociateRoute
									path='/process_catalog/:id'
									component={ProcessIndiv}
									exact={true}
								/>
								<PrivateAssociateRoute
									path='/associates'
									component={AssociatesPage}
									exact={true}
								/>
								<PrivateAssociateRoute
									path='/associates/:id'
									component={AssociatesIndividualPage}
									exact={true}
								/>
								<PrivateAssociateRoute
									path='/wizards_superAdmin'
									component={Wizard}
									exact={true}
								/>
								<PrivateAssociateRoute
									path='/wizards_superAdmin/:id'
									component={WizardIndiv}
									exact={true}
								/>
								<PrivateAssociateRoute
									path='/procedures_superAdmin'
									component={Procedure}
									exact={true}
								/>
								<PrivateAssociateRoute
									path='/procedures_superAdmin/:id'
									component={ProcedureIndiv}
									exact={true}
								/>
								<PrivateAssociateRoute
									path='/impression_formats_superAdmin'
									component={FormatImpression}
									exact={true}
								/>
								<PrivateAssociateRoute
									path='/impression_formats_superAdmin/:id'
									component={FormatImpressionIndiv}
									exact={true}
								/>
								<PrivateAssociateRoute
									path='/communications_superAdmin'
									component={Communication}
									exact={true}
								/>
								<PrivateAssociateRoute
									path='/communications_superAdmin/:id'
									component={CommunicationIndiv}
									exact={true}
								/>
							</>
						)}

						{/* <Route path="*" component={ModuleNotFound}/>  */}
					</Switch>
				</Suspense>
			</BrowserRouter>
		</React.Fragment>
	)
}
const mapDispatchToProps = (dispatch) => ({
	loginWithToken: (parmas) => dispatch(loginWithToken(parmas)),
	clientLoginWithToken: (params) => dispatch(clientLoginWithToken(params)),
	driverLoginWithToken: (params) => dispatch(driverLoginWithToken(params)),
	passengerLoginWithToken: (params) =>
		dispatch(passengerLoginWithToken(params)),
	supplierLoginWithToken: (params) => dispatch(supplierLoginWithToken(params)),
	associateLoginWithToken: (token) => dispatch(associateLoginWithToken(token)),
	passengerLoginWithToken: (params) =>
		dispatch(passengerLoginWithToken(params)),
	setLanguage: (lang) => dispatch(setLanguage(lang)),
})

const mapStateToProps = (state) => ({
	user: state.app.user,
	isAuthenticated: !!state.app.user,
	isClientAuthenticated: !!state.app.client,
	isDriverAuthenticated: !!state.app.driver,
	isPassengerAuthenticated: !!state.app.passenger,
	isSupplierAuthenticated: !!state.app.supplier,
	isAssociateAuthenticated: !!state.app?.superAdmin?.associate,
	isSuperAdminAuthenticated: !!state.app?.superAdmin?.associate?.super_admin,
	lang: state.app?.lang,
})

export default connect(mapStateToProps, mapDispatchToProps)(Routers)
