import crud from './_crud'
import { callApi } from 'utils/api'
import { showErrorMessage, showSuccessMessage } from 'utils/messages'
import { createHeaders, getRol } from 'utils/rol'

const createSupplier = (item, files, image) => {
	return async (dispatch, getState) => {
		const state = getState()
		console.log(files)
		const res = await callApi(`/supplier/create_supplier`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			json: false,
			body: {
				supplier: JSON.stringify(item),
				files,
				image,
			},
			successMessage: `Se agregó el proveedor correctamente.`,
		})
		showSuccessMessage('Los proveedores se registrarón correctamente')
		return res.body
	}
}

const updateSupplier = (item, files, image) => {
	return async (dispatch, getState) => {
		const state = getState()
		const res = await callApi(`/supplier/update_supplier`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			json: false,
			body: {
				supplier: JSON.stringify(item),
				files,
				image,
			},
			successMessage: `Se edito los proveedores correctamente.`,
		})
		showSuccessMessage('Los proveedores se editaron correctamente')
		return res.body
	}
}

const excelLayoutSuppliers = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier/generate_layout_excel_suppliers`, {
			method: 'PUT',
			headers: {
				Authorization: state.app.user.token,
			},
		})
			.then((res) => {
				let buff = Buffer.from(res.body)
				//res.body.blob();
				return buff
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([blob]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${options.entity_name}_layout.xlsx`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}
}

const excelLayoutContacts = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier/generate_layout_excel_contacts`, {
			method: 'PUT',
			headers: {
				Authorization: state.app.user.token,
			},
		})
			.then((res) => {
				let buff = Buffer.from(res.body)
				//res.body.blob();
				return buff
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([blob]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${options.entity_name}_layout.xlsx`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}
}

const importFileSuppliers = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier/import_data_from_excel_suppliers`, {
			method: 'PUT',
			json: false,
			headers: {
				Authorization: state.app.user.token,
			},
			body: {
				file: options.files,
			},
		})

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

const importFileContacts = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier/import_data_from_excel_contacts`, {
			method: 'PUT',
			json: false,
			headers: {
				Authorization: state.app.user.token,
			},
			body: {
				file: options.files,
			},
		})

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

const verifyImportDataSuppliers = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier/validate_data_from_import_suppliers`, {
			method: 'PUT',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify(options.data),
		})

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

const verifyImportDataContacts = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier/validate_data_from_import_contacts`, {
			method: 'PUT',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify(options.data),
		})

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

const importSuppliers = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier/import_suppliers`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				options,
			}),
			successMessage: `Se agreron los proveedores correctamente.`,
		})
		showSuccessMessage('Se registraron los proveedores correctamente')
		return res.body
	}
}

const importContacts = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier/import_contacts`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				options,
			}),
			successMessage: `Se agreron los contactos correctamente.`,
		})
		showSuccessMessage('Se registraron los contactos correctamente')
		return res.body
	}
}

const loadSuppliersByUsertype = ({ userType }) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier/get_suppliers_by_usertype`, {
			method: 'GET',
			headers: {
				Authorization: state.app[userType].token,
			},
			params: {
				userType: userType,
				sys_company_id: state.app[userType].sys_company_id,
				[`${userType}_id`]: state.app[userType].id,
			},
		})

		dispatch({
			type: `GET_ALL_SUPPLIERS_BY_USERTYPE`,
			payload: res.body,
		})

		return res.body
	}
}

const updateContacts = (data) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier/update_contacts`, {
			method: 'PATCH',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				contacts: data,
			}),
			successMessage: `Se editaron los contactos correctamente.`,
		})
		showSuccessMessage('Los contactos se editaron correctamente')
		return res.body
	}
}

const getSupplierByCode = (code, options) => {
	return async (dispatch, getState) => {
		const state = getState()
		const params = new URLSearchParams()
		options.params.fields.forEach((field) => params.append('fields', field))

		const url = `/supplier/get_supplier_by_code/${code}${
			params ? `?${params}` : ''
		}`

		const res = await callApi(url, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
		})

		return res.body
	}
}

const getSupplierPermissions = (supplierId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier_permits/${supplierId}`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
		})

		return res.body
	}
}

const saveSupplierPermissions = (permissions, supplierId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier_permits/${supplierId}`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({ permissions }),
		})

		return res.body
	}
}

const excelLayoutElectronicPayment = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/supplier/generate_layout_excel_electronic_payment`,
			{
				method: 'PUT',
				headers: {
					Authorization: state.app.user.token,
				},
			}
		)
			.then((res) => {
				let buff = Buffer.from(res.body)
				//res.body.blob();
				return buff
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([blob]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${options.entity_name}_layout.xlsx`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}
}

const importFileElectronicPayment = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/supplier/import_data_from_excel_electronic_payment`,
			{
				method: 'PUT',
				json: false,
				headers: {
					Authorization: state.app.user.token,
				},
				body: {
					file: options.files,
				},
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

const verifyImportDataElectronicPayment = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/supplier/validate_data_from_import_electronic_payment`,
			{
				method: 'PUT',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify(options.data),
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

const importElectronicPayment = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/supplier/import_electronic_payment`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				options,
			}),
			successMessage: `Se agreron los datos de pago correctamente.`,
		})
		showSuccessMessage('Se registraron los datos de pago correctamente')
		return res.body
	}
}

const getSupplierModules = () => {
	return async (dispatch, getState) => {
		const state = getState();

		const rol = getRol(state);
		const headers = createHeaders(state, rol);

		const res = await callApi(`/supplier_permits/supplier-modules`, {
			method: 'GET',
			headers,
		});

		return res.body;
	};
};

const getWebSupplierPermissions = (supplierId) => {
	return async (dispatch, getState) => {
		const state = getState();

		const rol = getRol(state);
		const headers = createHeaders(state, rol);

		const res = await callApi(`/supplier_permits/web/${supplierId}`, {
			method: 'GET',
			headers,
		});

		return res.body;
	};
};

const saveWebSupplierPermissions = (permissions, supplierId) => {
	return async (dispatch, getState) => {
		const state = getState();

		const res = await callApi(`/supplier_permits/web/${supplierId}`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({ web_permissions: permissions }),
		});

		return res.status === 200;
	};
};


const actions = crud('supplier', 'el proveedor', 'los proveedores')

export default {
	...actions,
	createSupplier,
	updateSupplier,
	importSuppliers,
	importContacts,
	excelLayoutSuppliers,
	importFileSuppliers,
	verifyImportDataSuppliers,
	excelLayoutContacts,
	importFileContacts,
	verifyImportDataContacts,
	loadSuppliersByUsertype,
	updateContacts,
	getSupplierByCode,
	getSupplierPermissions,
	saveSupplierPermissions,
	excelLayoutElectronicPayment,
	importFileElectronicPayment,
	verifyImportDataElectronicPayment,
	importElectronicPayment,
	getSupplierModules,
	getWebSupplierPermissions,
	saveWebSupplierPermissions,
}
