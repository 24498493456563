import { createHeaders, getRol } from 'utils/rol'
import crud from './_crud'
import { callApi } from 'utils/api'

const entity = 'driver'
const actions = crud(entity, 'el conductor', 'los conductores')

const loadDriverRoutes = (options = {}) => {
	return async (dispatch, getState) => {
		const state = getState()
		//  Check if the entities have been loaded before
		const res = await callApi(`/driver_site/custom`, {
			method: 'GET',
			headers: {
				Authorization: state.app.driver.token,
			},
			params: options.params,
		})

		dispatch({
			type: `LOAD_DRIVERS`,
			response: res.body,
		})

		return res.body
	}
}
const loadRoutes = (options = {}) => {
	return async (dispatch, getState) => {
		const state = getState()
		//  Check if the entities have been loaded before
		dispatch({
			type: `FETCH_${entity.toUpperCase()}S`,
		})
		const res = await callApi(`/driver_site/route`, {
			method: 'GET',
			headers: {
				Authorization: state.app.driver.token,
			},
			params: options.params,
		})

		dispatch({
			type: `LOAD_DRIVERS`,
			response: res.body,
		})

		return res.body
	}
}

const updateDriver = (item, shouldShowSuccessMessage = true) => {
	return async (dispatch, getState) => {
		const state = getState()
		let res = await callApi(`/driver_site/${item.id}`, {
			method: 'PATCH',
			body: JSON.stringify(item),
			headers: {
				Authorization: state.app.driver.token,
			},
			successMessage:
				shouldShowSuccessMessage && `Se actualizó el pasajero correctamente.`,
		})

		let stored_item = res.body

		dispatch({
			type: `UPDATE_DRIVER`,
			response: stored_item,
		})
	}
}
const getDrivers = (item, shouldShowSuccessMessage = true) => {
	return async (dispatch, getState) => {
		const state = getState()
		let res = await callApi(`/driver/utils/get_drivers`, {
			method: 'GET',
			body: JSON.stringify(item),
			headers: {
				Authorization: state.app.driver.token,
			},
			successMessage:
				shouldShowSuccessMessage && `Se actualizó el pasajero correctamente.`,
		})

		let stored_item = res.body

		dispatch({
			type: `UPDATE_DRIVER`,
			response: stored_item,
		})
	}
}

const getDriverPermissions = (driverId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/drivers_permits/${driverId}`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
		})

		return res.body
	}
}

const saveDriverPermissions = (permissions, driverId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/drivers_permits/${driverId}`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({ permissions }),
		})

		return res.body
	}
}

const getDriverModules = () => {
	return async (dispatch, getState) => {
		const state = getState()

		const rol = getRol(state)
		const headers = createHeaders(state, rol)

		const res = await callApi(`/drivers_permits/driver-modules`, {
			method: 'GET',
			headers,
		})

		return res.body
	}
}

const getWebDriverPermissions = (driverId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const rol = getRol(state)
		const headers = createHeaders(state, rol)

		const res = await callApi(`/drivers_permits/web/${driverId}`, {
			method: 'GET',
			headers,
		})

		return res.body
	}
}

const saveWebDriverPermissions = (permissions, driverId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/drivers_permits/web/${driverId}`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({ web_permissions: permissions }),
		})

		return res.status === 200
	}
}

export default {
	...actions,
	loadDriverRoutes,
	loadRoutes,
	updateDriver,
	getDrivers,
	getDriverPermissions,
	saveDriverPermissions,
	getDriverModules,
	getWebDriverPermissions,
	saveWebDriverPermissions,
}
